<template>
  <!--    编辑文件-->
  <el-dialog
    title=""
    :visible.sync="isShowdialogFileEdit"
    width="30%"
    append-to-body
  >
    <div slot="title" class="header-title">
      <!--        编辑-->
      <span>{{ $t("label.modify") + showData.name }}</span>
    </div>
    <div class="dialog-file-edit-item">
      <!--        文件名-->
      <div>
        <span style="color: red">*</span>{{ $t("label.addattachment.name") }}
      </div>
      <el-input v-model="editFileDetail.name"></el-input>
    </div>
    <div class="dialog-file-edit-item">
      <!--        描述-->
      <div>{{ $t("label.file.des") }}</div>
      <el-input v-model="editFileDetail.description"></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <!--        取消-->
      <el-button @click="isShowdialogFileEdit = false" :loading="editLoading">{{
        $t("label.cancel")
      }}</el-button>
      <!--        保存-->
      <el-button
        type="primary"
        @click="confirmFileEdit"
        :loading="editLoading"
        >{{ $t("label.save") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import * as api from "./api.js";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    dialogFileEdit: {
      type: Boolean,
      default: false,
    },
    showData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    // 获取回显数据
    api
      .editFile({
        id: this.id,
      })
      .then((res) => {
        this.editFileDetail = res.data;
      });
  },
  methods: {
    confirmFileEdit() {
      if (this.editFileDetail.name.replace(/\s+/g, "") !== "") {
        this.editLoading = true;
        api
          .saveEditFile({
            id: this.id,
            name: this.editFileDetail.name,
            description: this.editFileDetail.description || "",
          })
          .then(() => {
            this.isShowdialogFileEdit = false;
            // 保存成功 savesuccess
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("savesuccess"),
            });
            this.editLoading = false;
            // 编辑保存成功事件 发射给父组件
            this.$emit("editSaveSuccess");
          });
      } else {
        // 请填写文件名 label.weixin.input.filename
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.weixin.input.filename"),
        });
      }
    },
  },
  data() {
    return {
      editLoading: false, // 编辑
      editFileDetail: {}, // 编辑信息回显
    };
  },
  computed: {
    isShowdialogFileEdit: {
      get() {
        return this.dialogFileEdit;
      },
      set() {
        this.$emit("update:dialogFileEdit", false);
      },
    },
  },
};
</script>

<style>
</style>